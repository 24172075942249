// Remove `no-js` class
let localize
document.documentElement.classList.remove('no-js')

// Add your codes here'
document.addEventListener('DOMContentLoaded', (e) => {

  const bodyCt = document.querySelector('.body_app')
  const splashCt = document.querySelector('.splash')
  const homeCt = document.querySelector('.home')
  const mapCt = document.querySelector('.map')
  const salleCt = document.querySelector('.salle')
  const playerCt = document.querySelector('.player')
  const bonusCt = document.querySelector('.bonus')
  const headerCt = document.querySelector('.header')
  const footerCt = document.querySelector('.footer')
  let currentType,audioPlayer,currentSalle,currentLevel,headerTypeCt, history=[],content,toggleplaypause


  const itemMenu = document.querySelector('template#itemMenu');
  const itemSalleMenu = document.querySelector('template#itemSalleMenu');

  i18next
    .use(i18nextXHRBackend)
    .use(locI18next)
    .init({
      lng: 'fr',
      fallbackLng: 'fr',
      debug: false,
      backend: {
        loadPath: 'assets/locales/{{lng}}.json',
        crossDomain: true
      }
    }, function(err, t) {
      localize = locI18next.init(i18next,{
        selectorAttr: 'data-i18n', // selector for translating elements
        targetAttr: 'i18n-target',
        optionsAttr: 'i18n-options',
        useOptionsAttr: false,
        parseDefaultValueFromContent: true
      });
      content = i18next.t('content', { returnObjects: true })
			app.init();

    });

  const os = getMobileOperatingSystem()
  const app = {
    localize:() => {
      localize(".body_app");
    },
    init:() => {
        console.log();

        setTimeout( () => {
          app.localize()
          app.initPlayer()
          app.initHome()
        }, 3600);
        footerCt.querySelector('.prev').addEventListener('click', (e) => {
          app.back()
        })
        footerCt.querySelector('.home').addEventListener('click', (e) => {
          app.backHome()
        })
    },
    back:() => {
      document.querySelector('.page').scrollTo(0, 0);
      if(audioPlayer) {
        audioPlayer.pause()
        audioPlayer.src = ""
      }
      if (history.length>0) {
        const lastItem = history.pop()
        document.querySelector('.'+lastItem).classList.remove('show')
        if(bodyCt.classList.contains('p_'+lastItem)) {
          bodyCt.classList.remove('p_'+lastItem)
        }
        if(history[history.length-1] == 'home') {
          bodyCt.classList.remove(''+currentType)
          headerCt.querySelector('.header__nav').classList.remove('show')
          footerCt.classList.remove('active')
          headerCt.querySelector('.header__title').classList.remove('show')
        }
        if(history[history.length-1] == 'salle') {
          headerCt.querySelector('.header__title').classList.add('show')
        }
        if(history[history.length-1] == 'map') {
          headerCt.querySelector('.header__title').classList.remove('show')
          headerCt.querySelector('.header__nav').classList.add('show')
        }
        if (history[history.length-1]) {
          bodyCt.classList.add('p_'+history[history.length-1])
        }


      }
    },
    backHome:() => {
      if(audioPlayer) {
        audioPlayer.pause()
        audioPlayer.src = ""
      }
      bodyCt.classList.remove(''+currentType)

      headerCt.querySelector('.header__nav').classList.remove('show')
      headerCt.querySelector('.header__title').classList.remove('show')
      footerCt.classList.remove('active')

      if (history.length>0) {
        for (var i = 1; i < history.length; i++) {

          document.querySelector('.'+history[i]).classList.remove('show')
          if(bodyCt.classList.contains('p_'+history[i])) {
            bodyCt.classList.remove('p_'+history[i])
          }
        }
        history = ['home']
      }
    },
    initHome:() => {
      history.push('home')
      splashCt.classList.remove('show')
      splashCt.classList.remove('show')
      homeCt.classList.add('show')
      bodyCt.classList.remove('p_splash')
      bodyCt.classList.add('p_home')

      homeCt.querySelectorAll('[data-type]').forEach((item, i) => {
        item.addEventListener('click', (e) => {
          app.initType(e.currentTarget.dataset.type)
        })
      });
      headerCt.querySelector('.header__nav_left').addEventListener('click', (e) => {
        headerCt.querySelector('.header__nav_left').classList.add('active')
        headerCt.querySelector('.header__nav_right').classList.remove('active')

        mapCt.querySelector('.map_0').classList.add('active')
        mapCt.querySelector('.map_1').classList.remove('active')

        mapCt.querySelector('.menu__level0').classList.add('active')
        mapCt.querySelector('.menu__level1').classList.remove('active')

      })
      headerCt.querySelector('.header__nav_right').addEventListener('click', (e) => {
        mapCt.querySelector('.map_0').classList.remove('active')
        mapCt.querySelector('.map_1').classList.add('active')

        mapCt.querySelector('.menu__level0').classList.remove('active')
        mapCt.querySelector('.menu__level1').classList.add('active')

        headerCt.querySelector('.header__nav_left').classList.remove('active')
        headerCt.querySelector('.header__nav_right').classList.add('active')
      })
    },
    initType: (pType) => {
      mapCt.querySelector('.menu__level0').innerHTML=""
      mapCt.querySelector('.menu__level1').innerHTML=""
      history.push('map')
      bodyCt.classList.add('p_map')
      bodyCt.classList.remove('p_home')
      currentType = pType
      headerTypeCt = document.querySelector('.header__nav_'+currentType)
      bodyCt.classList.add(''+currentType)
      mapCt.classList.add('show')
      footerCt.querySelector('p').innerHTML = content[currentType].title

      for (var i = 0; i < content[currentType].level0.length; i++) {
        let itemNode  = itemMenu.content.cloneNode(true)
        let itemHTML = itemNode.firstElementChild
        itemHTML.dataset.salle = i
        itemHTML.dataset.level = 0
        itemHTML.querySelector('.title').innerHTML = content[currentType].level0[i].title
        itemHTML.querySelector('.bt_square p').innerHTML = content[currentType].level0[i].picto
        mapCt.querySelector('.menu__level0').append(itemHTML)
      }
      for (i = 0; i < content[currentType].level1.length; i++) {
        let itemNode  = itemMenu.content.cloneNode(true)
        let itemHTML = itemNode.firstElementChild
        itemHTML.dataset.salle = i
        itemHTML.dataset.level = 1
        itemHTML.querySelector('.title').innerHTML = content[currentType].level1[i].title
        itemHTML.querySelector('.bt_square p').innerHTML = content[currentType].level1[i].picto
        mapCt.querySelector('.menu__level1').append(itemHTML)
      }
      mapCt.querySelectorAll('[data-salle]').forEach((item, i) => {
        item.addEventListener('click', (e) => {
          app.initSalle(e.currentTarget.dataset.salle, e.currentTarget.dataset.level)
        })
      });

      headerCt.querySelector('.header__nav').classList.add('show')
      footerCt.classList.add('active')

      setTimeout(() => {

      }, 1000);
    },
    initSalle: (index, level) => {
      salleCt.querySelector('.salle__menu').innerHTML=""
      history.push('salle')
      currentSalle = index
      currentLevel = level
      salleCt.classList.add('show')
      bodyCt.classList.remove('p_map')
      bodyCt.classList.add('p_salle')

      headerCt.querySelector('.header__title').classList.add('show')
      headerCt.querySelector('.header__nav').classList.remove('show')
      headerCt.querySelector('.header__title p').innerHTML = content[currentType]['level'+level][index].title
      for (var i = 0; i < content[currentType]['level'+level][index]['menu'].length; i++) {
        let itemNode  = itemSalleMenu.content.cloneNode(true)
        let itemHTML = itemNode.firstElementChild


        itemHTML.querySelector('.title').innerHTML = content[currentType]['level'+level][index]['menu'][i].title
        itemHTML.dataset.index = i
        if(content[currentType]['level'+level][index]['menu'][i].type =="bonus") {
          itemHTML.querySelector('.picto').src = "assets/img/bonus.svg"
          itemHTML.addEventListener('click', (e) => {
            //bonus
            app.initBonus(e.currentTarget.dataset.index)
          })
        } else {
          // itemHTML.dataset.audio = content[currentType]['level'+level][index]['menu'][i].audio
          // itemHTML.dataset.titre = content[currentType]['level'+level][index]['menu'][i].title
          itemHTML.addEventListener('click', (e) => {
            app.displayPlayer(e.currentTarget.dataset.index)
            //
          })
        }
        // itemHTML.querySelector('.bt_square p').innerHTML = content[currentType].level0[i].picto
        salleCt.querySelector('.salle__menu').append(itemHTML)
      }
    },
    initPlayer: () => {
      audioPlayer = playerCt.querySelector('audio');
      toggleplaypause = playerCt.querySelector('.toggleplaypause');
      toggleplaypause.addEventListener('click', e => {
        if(audioPlayer.paused) {
          audioPlayer.play()
          toggleplaypause.querySelector('.play').classList.remove('active')
          toggleplaypause.querySelector('.pause').classList.add('active')
        } else {
          audioPlayer.pause()
          toggleplaypause.querySelector('.play').classList.add('active')
          toggleplaypause.querySelector('.pause').classList.remove('active')
        }
      })

      audioPlayer.addEventListener('ended', (e) => {
        toggleplaypause.querySelector('.play').classList.add('active')
        toggleplaypause.querySelector('.pause').classList.remove('active')
      })
      audioPlayer.addEventListener('timeupdate', (e) => {
        try {
          let pct = e.currentTarget.currentTime / e.currentTarget.duration * 100
          playerCt.querySelector('.progress').style.width = pct+"%"
          playerCt.querySelector('.cursor').style.left = pct+"%"
          playerCt.querySelector('.totaltime').innerHTML = new Date(e.currentTarget.duration * 1000).toISOString().slice(14, 19);
          playerCt.querySelector('.timespend').innerHTML = new Date(e.currentTarget.currentTime * 1000).toISOString().slice(14, 19);
        } catch (e) {
          //
        } finally {
          //
        }
      })
      if(os == "Android") {
        audioPlayer.addEventListener('canplay', (e) => {
          audioPlayer.play()
        })
      }


    },
    displayPlayer: (index) => {
      history.push('player')
      bodyCt.classList.remove('p_salle')
      bodyCt.classList.add('p_player')
      playerCt.classList.add('show')
      // headerCt.querySelector('.header__title').classList.remove('show')
      audioPlayer.src = content[currentType]['level'+currentLevel][currentSalle]['menu'][index].audio
      if(os == "Android") {
        toggleplaypause.querySelector('.play').classList.remove('active')
        toggleplaypause.querySelector('.pause').classList.add('active')
      } else {
        toggleplaypause.querySelector('.play').classList.add('active')
        toggleplaypause.querySelector('.pause').classList.remove('active')
      }
      playerCt.querySelector('h2').innerHTML = content[currentType]['level'+currentLevel][currentSalle]['menu'][index].title

    },
    initBonus: (index) => {
      bonusCt.querySelector('.bonus__list').innerHTML=""
      history.push('bonus')
      bodyCt.classList.remove('p_salle')
      bodyCt.classList.add('p_bonus')
      bonusCt.classList.add('show')
      bonusCt.querySelector('a').href = content[currentType]['level'+currentLevel][currentSalle]['menu'][index].route
      for (var i = 0; i < content[currentType]['level'+currentLevel][currentSalle]['menu'][index].medias.length; i++) {
        let itemNode  = bonusItem.content.cloneNode(true)
        let itemHTML = itemNode.firstElementChild

        itemHTML.querySelector('.visu').src = content[currentType]['level'+currentLevel][currentSalle]['menu'][index].medias[i].image
        itemHTML.querySelector('.caption').innerHTML = content[currentType]['level'+currentLevel][currentSalle]['menu'][index].medias[i].caption
        bonusCt.querySelector('.bonus__list').append(itemHTML)
      }
    }
  }
})
function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}
